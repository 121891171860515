<template>
  <div class="voice-search">
    <span @click="startRecording" class="voice-icon search" v-show="btn && resultsLen === 0">
      <i class="icon-sound cl-white" />
    </span>
    <small class="info" v-show="resultsLen === 0">Kliknij i powiedz</small>
    <div class="recording" v-show="btnStop">
      <div class="lds-facebook">
        <div />
        <div />
        <div />
        <div />
      </div>
      <div class="listening mt20">
        <b>{{ $t('Speak now') }}</b>
        <p class="listening--text-result">
          {{ textResult }}
        </p>
        <div class="voice-icon cancel mt15" @click="stopRecording">
          <img src="/assets/svg/Krzyzyk.svg" width="33px" height="33px" alt="krzyzyk" class="close-icon">
          <i class="icon-X_black" />
          <span class="hidden-xs">{{ $t('Cancel') }}</span>
        </div>
        <div class="voice-icon once-again mt15" @click="refreshRecording">
          <i class="icon-sound cl-white" />
          <span class="hidden-xs">{{ $t('Try again') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Speech } from 'src/modules/vsf-voice-search/components/Speech'

export default {
  mixins: [Speech]
}
</script>

<style lang="scss">
i:hover {
  opacity: 1;
}

.voice-search {
  padding: 15px 20px;
  text-align: center;
  @media (max-width: 768px) {
    padding: 0;
  }
}
.voice-icon {
  background: none;
  border: none;
  opacity: 0.6;
  height: 100%;
  width: 60px;
  i {
    font-size: 2.3em;
  }

  @media (min-width: 768px) {
    i {
      font-size: 1.5em;
    }
  }
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 35px;
  height: 15px;
  margin-bottom: 30px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background: #041D59;
  border-radius: 15px;
  height: 20px;
  width: 5px;
  opacity: 0.5;
  animation: lds-facebook 1s ease infinite;
}
.lds-facebook div:nth-child(1) {
  left: 0px;
  animation-delay: -0.36s;
}
.lds-facebook div:nth-child(2) {
  left: 10px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(3) {
  left: 20px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(4) {
  left: 30px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 0;
    opacity: 0.5;
  }
  50% {
    top: 10px;
    opacity: 1;
  }
  100% {
    top: 0;
    opacity: 0.5;
  }
}

.recording {
  background: #fff;
  position: absolute;
  margin-top: 60px;
  left: -5px;
  height: calc(85vh - 136px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  padding-top: 15vh;
}

.listening {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.listening--text-result {
  height: 60px;
  font-size: 1.5em;
}
@media (min-width: 768px) {
  .once-again,
  .cancel {
    display: flex;
    font-size: 0.8em;
    -ms-flex-pack: justify;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50px;
    padding: 5px 15px;
    margin-bottom: 15px;
    width: 120px;
    margin: 15px auto;
  }

  .once-again:hover,
  .cancel:hover {
    box-shadow: 0px 0px 14px -5px rgba(0, 0, 0, 0.7);
  }

  .once-again {
    border: 1px solid #041D59;
    color: #041D59;
  }

  .cancel {
    border: 1px solid #d1d1d1;
    color: #d1d1d1;
  }

  .once-again i,
  .cancel i {
    margin-right: 5px;
    opacity: 1;
  }

  .cancel span,
  .once-again span {
    display: inline-block;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .voice-search {
    z-index: 999
  }
  .voice-icon {
    position: fixed;
    left: calc(50% - 35px);
    opacity: 1;
    box-sizing: initial;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  .search, .once-again {
    bottom: 120px;
    width: 80px;
    height: 80px;
    i {
      font-size: 44px;
    }
  }
  .search {
    background-color: #041D59;
  }
  .once-again {
    background-color: #041D59;
  }

  .cancel {
    bottom: 200px;
    left: calc(50% - 30px);
    margin: 5px;
    width: 60px;
    height: 60px;
    background-color: #dfdfdf;
    i {
      font-size: 25px;
      font-weight: 700;
    }
  }

  .info {
    position: fixed;
    bottom: 100px;
    left: calc(50% - 40px);
    color: #041D59;
    margin-top: 15px;
    font-weight: 400;
    display: block;
    font-family: "sofia-pro", sans-serif;
  }
  .recording{
    height: calc(100vh - 136px);
  }
}
@media (min-width: 768px) {
  .info {
    display: none;
  }
}
</style>
