import openSocket from 'socket.io-client';
import config from 'config';

const socket = openSocket(
  config.apiKeys.voiceSearch
);

// ================= CONFIG =================
// Stream Audio
let bufferSize = 2048;
let AudioContext;
let context;
let processor;
let input;
let globalStream;

// lets
let streamStreaming = false;

// audioStream constraints
const constraints = {
  audio: true,
  video: false
};

export const Speech = {
  props: {
    voice: {
      type: Function,
      default: () => 1
    },
    resultsLen: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      btn: true,
      btnStop: false,
      loader: false,
      result: false,
      resultError: false,
      textResult: '',
      finalResult: ''
    };
  },
  methods: {
    startRecording () {
      this.clearVoiceResults();
      this.initRecording();
      this.btn = false;
      this.btnStop = true;
      setTimeout(() => {
        this.finalResult = this.textResult;
        this.stopRecording();
      }, 5000);
    },
    initRecording () {
      socket.emit('startGoogleCloudStream', ''); // init socket Google Speech Connection
      streamStreaming = true;
      AudioContext = window.AudioContext || window.webkitAudioContext;
      context = new AudioContext();
      processor = context.createScriptProcessor(bufferSize, 1, 1); // createScriptProcessor() OUTDATED AND UNSUPPORTED should be replaced by audioWorklet
      processor.connect(context.destination);

      let handleSuccess = stream => {
        globalStream = stream;
        input = context.createMediaStreamSource(stream);
        input.connect(processor);

        processor.onaudioprocess = e => {
          this.microphoneProcess(e);
        };
      };

      // ================= POLYFILL =================
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // Some browsers partially implement mediaDevices.
      // adding the getUserMedia property if it's missing.
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // First get ahold of the legacy getUserMedia, if present
          var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

          // Some browsers just don't implement it - return a rejected promise with an error
          // to keep a consistent interface
          if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
          }

          // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
          return new Promise((resolve, reject) => {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        }
      }
      navigator.mediaDevices.getUserMedia(constraints).then(handleSuccess).catch(err => err);
    },
    microphoneProcess (e) {
      let left = e.inputBuffer.getChannelData(0); // inheriting form AudioContext.createScriptProcessor() OUTDATED AND UNSUPPORTED
      let left16 = this.downsampleBuffer(left, 44100, 16000);
      socket.emit('binaryData', left16);
    },
    downsampleBuffer (buffer, sampleRate, outSampleRate) {
      if (outSampleRate === sampleRate) {
        return buffer;
      }
      if (outSampleRate > sampleRate) {
        return false;
      }
      let sampleRateRatio = sampleRate / outSampleRate;
      let newLength = Math.round(buffer.length / sampleRateRatio);
      let result = new Int16Array(newLength);
      let offsetResult = 0;
      let offsetBuffer = 0;
      while (offsetResult < result.length) {
        let nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
        let accum = 0;
        let count = 0;
        for (
          let i = offsetBuffer;
          i < nextOffsetBuffer && i < buffer.length;
          i++
        ) {
          accum += buffer[i];
          count++;
        }

        result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
        offsetResult++;
        offsetBuffer = nextOffsetBuffer;
      }
      return result.buffer;
    },
    stopRecording () {
      if (!streamStreaming) {
        this.clearVoiceResults();
        return Promise.resolve(false);
      } // stop disconnecting if already disconnected
      this.btn = true;
      this.btnStop = false;
      streamStreaming = false;
      socket.emit('endGoogleCloudStream', '');

      let track = globalStream && globalStream.getTracks()[0];
      track.stop();

      input.disconnect(processor);
      processor.disconnect(context.destination);

      if (this.finalResult && this.finalResult.length > 0) {
        this.voice(this.finalResult);
      }

      this.clearVoiceResults();

      return context.close().then(() => {
        input = null;
        processor = null;
        context = null;
        AudioContext = null;
        return true;
      });
    },
    refreshRecording () {
      this.stopRecording().then(res => {
        if (res) {
          this.startRecording();
        }
      });
    },
    clearVoiceResults () {
      this.textResult = '';
      this.finalResult = '';
    }
  },
  created () {
    // ================= SOCKET IO =================
    socket.on('connect', data => {
      socket.emit('join', 'Server Connected to Client');
    });

    socket.on('speechData', data => {
      let dataFinal = undefined || data.results[0].isFinal;
      if (dataFinal === false) {
        this.textResult = data.results[0].alternatives[0].transcript;
      } else if (dataFinal === true) {
        let finalString = data.results[0].alternatives[0].transcript;
        this.textResult = finalString;
        this.finalResult = finalString;
        this.stopRecording();
      }
    });

    // ================= OTHER STUFF =================

    window.onbeforeunload = () => {
      if (streamStreaming) {
        socket.emit('endGoogleCloudStream', '');
      }
    };
  }
}
