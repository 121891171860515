<template>
  <div class="searchpanel fixed mw-100 bg-cl-primary cl-accent" data-testid="searchPanel">
    <div class="container search-container">
      <div class="row">
        <div class="col-md-12 end-xs">
          <label for="search" class="visually-hidden">{{ $t('Search') }}</label>
          <div class="search-input-container">
            <div class="search-input-group" :class="visibleProducts.length === 0 ? 'before-search' : 'after-search'">
              <i class="icon-search cl-white search-icon" />
              <input
                ref="search"
                id="search"
                :autofocus="true"
                v-model="search"
                @input="makeSearch"
                @blur="$v.search.$touch()"
                class="search-panel-input"
                placeholder=""
                type="text"
                @keyup.enter="toggleQueryFilter"
                :validations="[
                  {
                    condition: $v.search.minLength,
                    text: $t('Pole musi mieć co najmniej 6 znaków.')
                  }
                ]"
              >
              <img v-if="search.length > 0 && visibleProducts.length >= 0" src="/assets/svg/Krzyzyk.svg" alt="krzyzyk" class="clear-icon" @click="clearSearchPanel">
            </div>
            <span class="cl-alternative close-icon lh30 m10 hidden-xl" @click="closeSearchpanel">
              {{ $t('Cancel') }}
            </span>
            <button-full
              v-show="visibleProducts.length"
              @click.native="toggleQueryFilter"
              class="button__show-all button__show-all--search pointer weight-700 hidden-xs"
            >
              {{ $t('Pokaż wszystkie wyniki') }}
            </button-full>
            <speech :voice="getVoiceResult" :results-len="visibleProducts.length" v-if="!visibleProducts.length" />
          </div>
        </div>
      </div>
      <div v-if="visibleProducts.length <= 0" class="search-panel__placeholder row">
        <div class="col-md-12">
          <p class="h1 cl-black">
            {{ $t('Tell us what you are looking for') }}
          </p>
        </div>
      </div>
      <div class="results" v-if="search && search.length > 0">
        <div class="button-container">
          <button-full
            v-if="visibleProducts.length"
            @click.native="toggleQueryFilter"
            class="button__show-all pointer weight-700 col-xs-12 center-xs visible-xs"
          >
            {{ $t('Pokaż wszystkie wyniki') }}
          </button-full>
        </div>
        <transition name="fade">
          <div
            v-if="getNoResultsMessage"
            class="no-results relative center-xs h4 col-md-12"
          >
            {{ $t(getNoResultsMessage) }}
          </div>
        </transition>
        <div v-if="visibleProducts.length && categories.length > 1" class="categories px30">
          <category-panel :categories="categories" v-model="selectedCategoryIds" />
        </div>
        <div class="product-listing row px15">
          <product-tile
            v-for="(product, index) in visibleProducts"
            :key="product.id"
            :product="product"
            @click.native="closeSearchpanel"
            :position="index"
            list="search"
          />
        </div>
      </div>
      <div
        v-show="OnlineOnly"
        v-if="visibleProducts.length >= 4"
        class="buttons-set align-center py25 px20 hidden-xs"
      >
        <button-full
          v-if="visibleProducts.length"
          @click.native="toggleQueryFilter"
          class="button__show-all--footer pointer weight-700 hidden-xs"
        >
          {{ $t('Pokaż wszystkie wyniki') }}
        </button-full>
      </div>
    </div>
  </div>
</template>

<script>
import SearchPanel from '@vue-storefront/core/compatibility/components/blocks/SearchPanel/SearchPanel';
import ProductTile from 'theme/components/core/ProductTile';
import VueOfflineMixin from 'vue-offline/mixin';
import CategoryPanel from 'theme/components/core/blocks/Category/CategoryPanel';
import Speech from 'theme/components/core/Speech.vue';
import { minLength } from 'vuelidate/lib/validators';
// import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import CloseSvg from 'theme/components/theme/blocks/Icons/CloseSvg.vue'

export default {
  components: {
    ProductTile,
    CategoryPanel,
    Speech,
    ButtonFull,
    CloseSvg
  },
  mixins: [SearchPanel, VueOfflineMixin],
  validations: {
    search: {
      minLength: minLength(3)
    },
    searchInput: {
      minLength: minLength(3)
    }
  },
  data () {
    return {
      selectedCategoryIds: [],
      voiceResult: null
    };
  },
  computed: {
    visibleProducts () {
      const productList = this.products || [];
      if (this.selectedCategoryIds.length) {
        return productList.filter(product =>
          product.category_ids.some(categoryId => {
            const catId = parseInt(categoryId);
            return this.selectedCategoryIds.includes(catId);
          })
        );
      }
      return productList;
    },
    categories () {
      const categories = this.products
        .filter(p => p.category)
        .map(p => p.category)
        .flat()

      const discinctCategories = Array.from(
        new Set(categories.map(c => c.category_id))
      ).map(catId => categories.find(c => c.category_id === catId))

      return discinctCategories
    },
    getNoResultsMessage () {
      let msg = '';
      if (!this.$v.search.minLength) {
        msg = 'Searched term should consist of at least 3 characters.';
      } else if (this.emptyResults) {
        msg = 'No results were found.';
      }
      return msg;
    }
  },
  watch: {
    categories () {
      this.selectedCategoryIds = [];
    }
  },
  mounted () {
    let body = document.getElementsByTagName('body')[0]
    body.classList.add('iphone-scroll')
    this.$refs.search.focus()
    // disableBodyScroll(this.$el)
  },
  destroyed () {
    let body = document.getElementsByTagName('body')[0]
    body.classList.remove('iphone-scroll')
    // clearAllBodyScrollLocks()
  },
  methods: {
    getVoiceResult (result) {
      if (result) {
        this.search = result;
        this.makeSearch();
      }
    },
    clearSearchPanel () {
      this.search = ''
      this.makeSearch();
    },
    toggleQueryFilter () {
      this.closeSearchpanel()
      this.$router.push({ path: '/kategorie-produktow', query: { query: this.search } })
      this.search = ''
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/animations/transitions";
@import "~theme/css/variables/grid";
@import "~theme/css/variables/typography";

.searchpanel {
  height: 100vh;
  width: 800px;
  top: 0;
  right: 0;
  z-index: 3;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch !important;
  @media (min-width: 768px) {
    overflow-y: auto;
    overflow-x: hidden;
  }
  @media (max-width: 768px) {
    .results {
      overflow-y: auto;
      overflow-x: hidden;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch !important;
      height: calc(100vh - 135px);
    }
  }
  // .close-icon-row {
  //   font-size: 14px;
  //   display: flex;
  //   justify-content: flex-end;
  //   height: 50px;
  //   align-items: center;
  //   i {
  //     padding: 15px 25px;
  //   }
  //   @media (max-width: 1024px) {
  //     display: none;
  //   }
  // }

  .container {
    padding-left: 40px;
    padding-right: 40px;
    @media #{$media-xs} {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      width: 100%;
      margin: 0;
    }
  }

  .row {
    margin-left: -map-get($grid-gutter-widths, lg) / 2;
    margin-right: -map-get($grid-gutter-widths, lg) / 2;

    @media #{$media-xs} {
      margin-right: -map-get($grid-gutter-widths, xs) / 2;
      margin-left: -map-get($grid-gutter-widths, xs) / 2;
    }
  }

  .col-md-12 {
    padding-left: map-get($grid-gutter-widths, lg) / 2;
    padding-right: map-get($grid-gutter-widths, lg) / 2;

    @media #{$media-xs} {
      padding-left: map-get($grid-gutter-widths, xs) / 2;
      padding-right: map-get($grid-gutter-widths, xs) / 2;
    }
  }

  .product-listing {
    z-index: 4;
    padding-bottom: 30px;
    @media only screen and (min-device-width : 375px) and (max-device-width : 812px)  and (-webkit-device-pixel-ratio : 3) and (orientation : portrait) {
      padding-bottom: 120px;
    }
  }

  .product {
    box-sizing: border-box;
    width: 33.33%;
    padding-left: map-get($grid-gutter-widths, lg) / 2;
    padding-right: map-get($grid-gutter-widths, lg) / 2;

    @media #{$media-xs} {
      width: 50%;
      padding-left: map-get($grid-gutter-widths, xs) / 2;
      padding-right: map-get($grid-gutter-widths, xs) / 2;
    }
  }

  .close-icon {
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    @media (min-width: 769px) {
      display: none;
    }
  }

  .search-input-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #bdbdbd;
  }

  .search-input-group {
    position: relative;
    padding-right: 40px;
    transition: width 0.5s;
    @media (min-width: 1025px) {
      width: 95%;
    }
    @media (max-width: 1024px) {
      width: 100%;
      background: #fff;
      top: 0;
      margin-right: 10px;
    }
  }

  .search-input-group.after-search {
    @media (min-width: 1024px) {
      width: 60%;
    }
  }

  .search-icon {
    position: absolute;
    left: 20px;
    top: 35%;
    display: flex;
    align-items: center;
    font-size: 18px;
    justify-content: center;
    filter: contrast(0.0);
  }

  .clear-icon {
    width: 13px;
    height: 13px;
    position: absolute;
    right: 10px;
    top: 34%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 10px;
    background-color: #ababab;
    color: #f5f5f5;
    padding: 5px;
    border-radius: 50%;
    @media #{$media-xs} {
      top: 30%;
      right: 15px;
    }
  }

  .search-panel-input {
    width: 100%;
    margin: 5px 15px;
    height: auto;
    padding-left: 40px;
    border: none;
    outline: 0;
    font-size: 18px;
    font-family: "protipo",sans-serif;
    -webkit-appearance: none;
    background-color: #f5f5f5;
    border-radius: 15px;

    @media #{$media-xs} {
      margin: 5px 5px;
      font-size: 16px;
    }
  }

  .no-results {
    width: 100%;
  }

  i {
    opacity: 0.6;
  }

  i:hover {
    opacity: 1;
  }

  .close-button {
    background: #fff;
  }

  button {
    @media #{$media-xs} {
      width: 100%;
    }
  }
}

.button {
  &-container {
    z-index: 5;
    background-color: #fff;
    padding: 15px 30px 0 30px;
  }
  &__show-all--search {
    margin: 5px 25px;
    padding: 0 20px !important;
    white-space: nowrap;
    font-size: 14px;
    // transition: all 1s;
  }
  &__show-all--footer {
    width: 80%;
    height: 40px;
  }
  @media (max-width: 768px) {
    &-container {
      background-color: #fff;
      z-index: 5;
      padding: 0;
    }
    &__show-all {
      z-index: 5;
      position: fixed;
      bottom: 30px;
      margin-left: 0;
      margin-bottom: env(safe-area-inset-bottom);
    }
    &__show-all--search {
      display: none;
    }
  }
}

.buttons-set {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

input#search:-webkit-autofill,
input#search:-webkit-autofill:hover,
input#search:-webkit-autofill:focus {
  background-color: #fff !important;
}

.search-panel__placeholder p {
  font-weight: bold;
  font-size: 1.6em;
  padding: 0 50px;
  line-height: 1.2em;
}
</style>
